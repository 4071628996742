import { FadeLoader } from "react-spinners";

//Loader component
const Loader = () => {
  return (
    <div className="h-[100vh] items-center object-center justify-center content-center text-center w-[100vw] bg-gray-100">
      {/* Full-screen container with gray background */}
      <div>
        {/* Padding for content */}
        <span>
          <FadeLoader
            className="loading-img animate-pulse mt-[-1rem] max-[380px]:mt-[-4.5rem] lg:mt-[-4rem] mx-auto my-auto w-[200px] md:w-[300px]"
            color="#000d6b"
          />
        </span>
      </div>
    </div>
  );
};

export default Loader;
