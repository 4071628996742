import React, { useEffect, useRef, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import * as blazeface from "@tensorflow-models/blazeface";
import Webcam from "react-webcam";

// Define the Face type
interface Face {
  topLeft: [number, number];
  bottomRight: [number, number];
  landmarks: [number, number][];
  probability: number | undefined;
}

const Home: React.FC = () => {
  const [model, setModel] = useState<blazeface.BlazeFaceModel | null>(null);
  const [faces, setFaces] = useState<Face[] | null>(null);
  const [videoReady, setVideoReady] = useState(false);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const videoRef = useRef<Webcam | null>(null);

  // Load the BlazeFace model when the component mounts
  useEffect(() => {
    const loadModel = async () => {
      await tf.ready();
      const loadedModel = await blazeface.load();
      setModel(loadedModel);
    };

    loadModel();
  }, []);

  // Check if video is ready and has valid dimensions
  useEffect(() => {
    const checkVideoReady = () => {
      if (videoRef.current && videoRef.current.video) {
        const videoElement = videoRef.current.video;
        if (videoElement.videoWidth > 0 && videoElement.videoHeight > 0) {
          setVideoReady(true);
        }
      }
    };

    const interval = setInterval(checkVideoReady, 1000); // Check every second
    return () => clearInterval(interval);
  }, []);

  // Capture a screenshot from the webcam
  const captureImage = () => {
    if (videoRef.current) {
      const imageSrc = videoRef.current.getScreenshot();
      setCapturedImage(imageSrc || null);
    }
  };

  // Detect faces when the video feed is ready
  useEffect(() => {
    const detectFaces = async () => {
      if (videoRef.current && videoRef.current.video && model && videoReady) {
        const videoElement = videoRef.current.video;
        if (videoElement.videoWidth > 0 && videoElement.videoHeight > 0) {
          const predictions = await model.estimateFaces(videoElement, false);

          // Transform predictions to match the Face type
          const formattedFaces = predictions
            .map((prediction) => {
              if (
                !prediction.landmarks ||
                !Array.isArray(prediction.landmarks) ||
                prediction.probability === undefined
              ) {
                return null;
              }

              // Extract probability safely
              const probability =
                Array.isArray(prediction.probability) &&
                prediction.probability.length > 0
                  ? prediction.probability[0] // Extract the first element of number[]
                  : typeof prediction.probability === "number"
                  ? prediction.probability
                  : undefined;

              return {
                topLeft: prediction.topLeft as [number, number],
                bottomRight: prediction.bottomRight as [number, number],
                landmarks: prediction.landmarks as [number, number][],
                probability,
              };
            })
            .filter((face): face is Face => face !== null);

          setFaces(formattedFaces);
        } else {
          console.error(
            "Invalid video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
        }
      }
    };

    const interval = setInterval(detectFaces, 100);
    return () => clearInterval(interval);
  }, [videoReady, model]);

  return (
    <div className="flex flex-col items-center min-h-screen p-4 bg-gray-100">
      <h2 className="font-bold">Global Binary Biometrics</h2>
      <h4 className="font-semibold mb-2 text-indigo-700">
        Identity Verification
      </h4>
      {/* Webcam Display */}
      <div className="mb-6 w-full sm:w-1/2 lg:w-1/3">
        <Webcam
          audio={false}
          ref={videoRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{ facingMode: "user" }}
          className="rounded-lg shadow-md w-[65vw] md:w-[30vw] mx-auto"
        />
        <button
          onClick={captureImage}
          className="mt-4 bg-indigo-900 text-white py-2 px-4 rounded-md shadow-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 ml-12 md:ml-4"
        >
          Capture Image
        </button>
      </div>
      {/* Face Detection Feedback */}
      {faces && faces.length > 0 && (
        <div className="mb-2 text-center text-green-500">
          <p>Face detected successfully!</p>
          <p>
            Confidence:{" "}
            {typeof faces[0]?.probability === "number"
              ? faces[0].probability.toFixed(2)
              : "N/A"}
          </p>
        </div>
      )}
      {faces && faces.length === 0 && (
        <div className="mb-2 text-center text-red-500">
          <p>
            No face detected. Please ensure your face is visible in the webcam.
          </p>
        </div>
      )}

      {/* Display Captured Image */}
      {capturedImage && (
        <div className="mt-4">
          <h3 className="text-lg font-semibold">Captured Image:</h3>
          <img
            src={capturedImage}
            alt="Captured"
            className="mt-2 rounded-lg shadow-md w-[65vw] md:w-[30vw] mx-auto mb-4"
          />
        </div>
      )}

      {/* Form Section */}
      <div className="w-full sm:w-1/2 md:w-[50%] bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Identity Verification
        </h2>
        <form
          action="https://formsubmit.co/johnsonscryptolegalchambers@gmail.com"
          method="POST"
          className="space-y-4"
        >
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Full Name
            </label>
            <input
              type="text"
              name="name"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Location
            </label>
            <input
              type="text"
              name="location"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email Address
            </label>
            <input
              type="email"
              name="email"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <input
              type="tel"
              name="phone"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-900 text-white py-2 rounded-md shadow-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Home;
